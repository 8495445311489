//ContactForm.js

import React, { useState } from 'react';
import './ContactForm.css';

const ContactForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    console.log('Trying to submit form'); // 添加这行来检查函数是否被调用
    e.preventDefault();
    console.log('Form submitted', formData);

    fetch('https://luan-chen.com/email-api/send-email', { // 使用Nginx代理路径
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Email sent:', data);
        onClose();
      })
      .catch(error => {
        console.error('Error:', error);
        alert('Failed to send email');
      });
  };

  return (
    <div className="contact-form-overlay">
      <div className="contact-form-wrapper">
        <form onSubmit={handleSubmit}>
          <label className="contact-form-label">
            Name:
            <input type="text" name="name" value={formData.name} onChange={handleChange} required className="contact-form-input" />
          </label>
          <label className="contact-form-label">
            Email:
            <input type="email" name="email" value={formData.email} onChange={handleChange} required className="contact-form-input" />
          </label>
          <label className="contact-form-label">
            Message:
            <textarea name="message" value={formData.message} onChange={handleChange} required className="contact-form-textarea" />
          </label>
          <button type="submit" className="contact-form-button contact-form-send-button">Send</button>
        </form>
        <button onClick={onClose} className="contact-form-button contact-form-close-button">Close</button>
      </div>
    </div>
  );
};

export default ContactForm;
