// life.js

import React from 'react';
import { Link } from 'react-router-dom';
import './life.css';

const Life = () => {
  return (
    <div className="life-page">
      {/* Life 页面的其他内容可以放在这里 */}

    </div>
  );
};

export default Life;