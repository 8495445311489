// Blog.js
import React, { useState, useEffect } from 'react';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import './Blog.css';

import Life from './Blog/life/life';
import Learning from './Blog/learning/learning';
import Thoughts from './Blog/thoughts/thoughts';

const BlogContent = () => {
  const [displayText, setDisplayText] = useState([]);
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  const textPairs = [
    ["Hello! I'm delighted to meet you here. I'm Lu-An Chen.", 
     "您好！很高興能與您在此見面。我是Lu-An Chen。"],
    ["I don't use any social media platforms; this is my sole connection to the world.", 
     "我沒有使用任何社群媒體平台；這裡是我與世界唯一的連接。"],
    ["I have a lot I want to share about my world, which I'll present here in Chinese (as it best expresses my thoughts), occasionally accompanied by images.", 
     "關於我的世界，有很多想要分享，會在這裡使用中文（因為它最能表達我的想法）呈現，偶爾會配上圖片。"],
    ["I've categorized my content into three areas: 'Life', 'Learning', and 'Thoughts'.", 
     "我將內容分為三個領域：「生活」、「學習」和「思考」。"],
    ["'Life' not only records my daily experiences but also includes my reflections on the 'essence of life'.", 
     "「生活」除了記錄日常以外，會加上我對於「生命本質」的思考。"],
    ["'Learning' is a record of my studies, along with my thoughts on the 'nature of knowledge'.", 
     "「學習」則是我的學習紀錄，並加上我對於「知識本質」的思考。"],
    ["Finally, 'Thoughts' is where I attempt to record my reflections on artificial intelligence and the 'essence of being human', covering topics related to philosophy.", 
     "最後，「思考」則是我試圖將人工智慧與「人的本質」思考記錄下來，是和哲學相關的主題。"],
    ["If anything I share resonates with you, please feel free to get in touch.", 
     "如果我分享的任何內容引起您的共鳴，歡迎隨時與我聯絡。"]
  ];

  useEffect(() => {
    let currentPairIndex = 0;
    let currentCharIndex = 0;
    const maxLength = Math.max(...textPairs.map(pair => Math.max(pair[0].length, pair[1].length)));

    const typing = setInterval(() => {
      if (currentPairIndex < textPairs.length) {
        const currentPair = textPairs[currentPairIndex];
        const newDisplayText = [...displayText];
        
        if (currentCharIndex < maxLength) {
          if (!newDisplayText[currentPairIndex]) {
            newDisplayText[currentPairIndex] = ["", ""];
          }
          newDisplayText[currentPairIndex][0] = currentPair[0].slice(0, currentCharIndex + 1);
          newDisplayText[currentPairIndex][1] = currentPair[1].slice(0, currentCharIndex + 1);
          currentCharIndex++;
        } else {
          currentPairIndex++;
          currentCharIndex = 0;
        }
        
        setDisplayText(newDisplayText);
      } else {
        clearInterval(typing);
        setTimeout(() => setIsTypingComplete(true), 1000);
      }
    }, 50);

    return () => {
      clearInterval(typing);
    };
  }, []);

  return (
    <div className="blog-container">
      <div className={`blog-intro-text ${isTypingComplete ? 'full-text-display' : ''}`}>
        {isTypingComplete ? (
          textPairs.map((pair, index) => (
            <div key={index} className="text-pair">
              <div className="blog-text">{pair[0]}</div>
              <div className="blog-text-zh">{pair[1]}</div>
            </div>
          ))
        ) : (
          displayText.map((pair, index) => (
            <div key={index} className="text-pair">
              <div className="blog-typing-text">{pair[0]}</div>
              <div className="blog-typing-text-zh">{pair[1]}</div>
            </div>
          ))
        )}
      </div>
      <div className="blog-navigation-buttons">
        <Link to="/blog/life" className="blog-nav-button">Life</Link>
        <Link to="/blog/learning" className="blog-nav-button">Learning</Link>
        <Link to="/blog/thoughts" className="blog-nav-button">Thoughts</Link>
      </div>
    </div>
  );
};

const Blog = () => {
  const location = useLocation();
  const isMainBlogPage = location.pathname === '/blog';

  return (
    <div>
      {isMainBlogPage && <BlogContent />}
      <Routes>
        <Route path="life" element={<Life />} />
        <Route path="learning" element={<Learning />} />
        <Route path="thoughts" element={<Thoughts />} />
      </Routes>
    </div>
  );
};

export default Blog;