// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './HomePage';
import About from './About';
import Blog from './Blog';
import Services from './Pervices';
import GlobalNav from './components/GlobalNav';
import ExternalRedirect from './components/ExternalRedirect';
import BookingSystemMain from './BookingSystem/BookingSystemMain';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/secure-booking-yT9x2/*" element={<BookingSystemMain />} />
        <Route path="/*" element={<MainWebsite />} />
      </Routes>
    </Router>
  );
}

const MainWebsite = () => {
  return (
    <>
      <GlobalNav show={true} />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog/*" element={<Blog />} />
        <Route path="/projects" element={<Services />} />
        <Route path="/share" element={<ExternalRedirect to="https://drive.google.com/drive/folders/17OD6Z2HoDnxLDDvWpiHBrxcT9Se_n0jv?usp=sharing" />} />
      </Routes>
    </>
  );
}

export default App;