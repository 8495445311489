import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './GlobalNav.css';
import ContactForm from './ContactForm';

const GlobalNav = ({ show }) => {
  const [isContactFormVisible, setContactFormVisible] = useState(false);
  const location = useLocation();

  const toggleContactForm = () => {
    setContactFormVisible(!isContactFormVisible);
  };

  if (!show) {
    return null;
  }

  const showBackToBlog = location.pathname.startsWith('/blog/') && location.pathname !== '/blog';

  return (
    <div className="global-nav-container">
      <Link to="/" className="global-nav-button">Home</Link>
      {showBackToBlog ? (
        <Link to="/blog" className="global-nav-button">Back</Link>
      ) : (
        <div className="global-nav-placeholder"></div>
      )}
      <button className="global-nav-button" onClick={toggleContactForm}>Contact</button>
      {isContactFormVisible && <ContactForm onClose={toggleContactForm} />}
    </div>
  );
}

export default GlobalNav;