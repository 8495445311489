// src/BookingSystem/BookingSystemMain.js
import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';

const API_URL = 'https://luan-chen.com/api';

const BookingSystemMain = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkLoginStatus = async () => {
      const token = localStorage.getItem('token');
      console.log('Checking login status, token:', token ? 'exists' : 'not found');
      if (token) {
        try {
          console.log('Verifying token...');
          const response = await fetch(`${API_URL}/auth/verify`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          console.log('Verify response:', response.status, response.statusText);
          if (response.ok) {
            const userData = await response.json();
            console.log('User data:', userData);
            setUser(userData.user);
            if (location.pathname === '/secure-booking-yT9x2') {
              console.log('Redirecting to dashboard');
              navigate('/secure-booking-yT9x2/dashboard', { replace: true });
            }
          } else {
            console.log('Token verification failed');
            localStorage.removeItem('token');
            setUser(null);
            navigate('/secure-booking-yT9x2', { replace: true });
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          localStorage.removeItem('token');
          setUser(null);
          navigate('/secure-booking-yT9x2', { replace: true });
        }
      } else {
        console.log('No token found, staying on login page');
        setUser(null);
      }
      setLoading(false);
    };

    checkLoginStatus();
  }, [navigate, location]);

  const handleLogin = (userData) => {
    setUser(userData);
    navigate('/secure-booking-yT9x2/dashboard', { replace: true });
  };

  const handleUpdateUser = (updatedUserData) => {
    setUser(prevUser => ({ ...prevUser, ...updatedUserData }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route 
        path="/" 
        element={user ? <Navigate to="/secure-booking-yT9x2/dashboard" replace /> : <Login onLogin={handleLogin} />} 
      />
      <Route 
        path="dashboard" 
        element={user ? <Dashboard user={user} onUpdateUser={handleUpdateUser} /> : <Navigate to="/secure-booking-yT9x2" replace />} 
      />
      <Route
        path="*"
        element={<Navigate to="/secure-booking-yT9x2" replace />}
      />
    </Routes>
  );
};

export default BookingSystemMain;