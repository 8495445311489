// src/BookingSystem/UpdateProfile.js
// src/BookingSystem/UpdateProfile.js
import React, { useState } from 'react';
import './UpdateProfile.css';

const API_URL = 'https://luan-chen.com/api';

const UpdateProfile = ({ user, onUpdateUser }) => {
  const [username, setUsername] = useState(user.username);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState(user.name);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // 檢查密碼是否一致
    if (password !== confirmPassword) {
      setError('兩次輸入的密碼不一致');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_URL}/auth/updateUser`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ username, password, name }),
      });

      if (!response.ok) {
        const text = await response.text();
        try {
          const data = JSON.parse(text);
          throw new Error(data.message || '修改失敗');
        } catch (e) {
          throw new Error(text || '修改失敗');
        }
      }
      const data = await response.json();

      setSuccess('個人資料修改成功');
      onUpdateUser(data.user);
    } catch (error) {
      setError(error.message || '修改失敗，請重試');
    }
  };

  return (
    <div className="update-profile-form">
      <h2>修改個人資料</h2>
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">{success}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>新帳號：</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>新密碼：</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="留空則不修改"
          />
        </div>
        <div className="form-group">
          <label>確認新密碼：</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="再次輸入新密碼"
          />
        </div>
        <div className="form-group">
          <label>姓名：</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <button type="submit">修改個人資料</button>
      </form>
    </div>
  );
};

export default UpdateProfile;