// About.js

import React, { useEffect, useState } from 'react';
import './About.css';

const About = () => {
  const [displayText, setDisplayText] = useState('');
  const text = `
  -Education-
    2010 KSHS in Kaohsiung, Taiwan
    2014 CPU, Department of Forensic Science
    2020 NTOU, Master's Degree in Law
    2024 NYCU, Technology Crime Investigation

  -Work Experience-
    2017 to present CIB

  -IT Background-
    2023 Black Hat (Ghidra)
    iPAS Information Security Engineer (2022)
    https://ieeexplore.ieee.org/document/10258174

  -Research Interests-
    Machine Learning, Deep Learning
    Software Development
    Reverse Engineering
`;

  useEffect(() => {
    let i = 0;
    const typing = setInterval(() => {
      setDisplayText(text.slice(0, i));
      i++;
      if (i > text.length) {
        clearInterval(typing);
      }
    }, 30);

    return () => {
      clearInterval(typing);
    };
  }, []);

  return (
    <div className="about-container">
      <div className="about-intro-text">
        <div className="about-typing-text">{displayText}</div>
      </div>
    </div>
  );
};

export default About;