import React from 'react';
import { Link } from 'react-router-dom';
import './learning.css';

const Learning = () => {
  return (
    <div className="learning-page">
      {/* Learning 页面的其他内容 */}
    </div>
  );
};

export default Learning;