// src/BookingSystem/BookingForm.js
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './BookingForm.css'; 

const venues = ['1A', '2A', '2B', '2C', '2D', '2E', '3A', '3B', '3C', '3D', '3E', '3F', 'X', 'Y', 'Z'];

const BookingForm = ({ onSubmit, onCancel, onDelete, initialData, isCurrentUser, currentUserId, isAdmin, users }) => {
  console.log('BookingForm props:', { initialData, isCurrentUser, currentUserId, isAdmin, users });

  const [formData, setFormData] = useState({
    startTime: '',
    endTime: '',
    venue: '',
    participants: ['', '', '', '', ''],
    notes: '',
    actingUserId: '',
  });
  const [recurrence, setRecurrence] = useState('none');
  const [recurrenceEnd, setRecurrenceEnd] = useState('3months');
  const [updateType, setUpdateType] = useState('single');
  const [deleteType, setDeleteType] = useState('single');

  useEffect(() => {
    if (initialData) {
      setFormData({
        startTime: moment(initialData.start).toDate(),
        endTime: moment(initialData.end).toDate(),
        venue: initialData.venue,
        participants: initialData.participants || ['', '', '', '', ''],
        notes: initialData.notes || '',
        actingUserId: initialData.userId || '',
      });
      setRecurrence(initialData.recurrence || 'none');
      setRecurrenceEnd(initialData.recurrenceEnd || '3months');
    }
  }, [initialData]);

  const handleTimeChange = (date, name) => {
    const roundedDate = moment(date).minutes(Math.round(moment(date).minutes() / 30) * 30).toDate();
    setFormData({ ...formData, [name]: roundedDate });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleParticipantChange = (index, value) => {
    const newParticipants = [...formData.participants];
    newParticipants[index] = value;
    setFormData({ ...formData, participants: newParticipants });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const submissionData = {
      ...formData,
      startTime: moment(formData.startTime).toISOString(),
      endTime: moment(formData.endTime).toISOString(),
      recurrence,
      recurrenceEnd,
      updateType
    };
    onSubmit(submissionData);
  };

  const handleDelete = () => {
    const confirmMessage = deleteType === 'single' 
      ? '您確定要刪除此預約嗎？' 
      : '您確定要刪除所有相關的週期預約嗎？這將刪除所有未來的週期預約。';
    
    if (window.confirm(confirmMessage)) {
      onDelete(deleteType);
    }
  };

  const isEditable = isAdmin;

  return (
    <form onSubmit={handleSubmit} className="booking-form">
      {isAdmin && users && (
        <div className="form-group">
          <label>代表用戶</label>
          <select 
            name="actingUserId" 
            value={formData.actingUserId} 
            onChange={handleChange}
            disabled={!isEditable}
          >
            <option value="">選擇用戶</option>
            {users.map(user => (
              <option key={user._id} value={user._id}>{user.name}</option>
            ))}
          </select>
        </div>
      )}
      
      <div className="form-row">
        <div className="form-group">
          <label>開始時間</label>
          <DatePicker
            selected={formData.startTime}
            onChange={(date) => handleTimeChange(date, 'startTime')}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={30}
            timeCaption="時間"
            dateFormat="yyyy-MM-dd HH:mm"
            disabled={!isEditable}
          />
        </div>
        <div className="form-group">
          <label>結束時間</label>
          <DatePicker
            selected={formData.endTime}
            onChange={(date) => handleTimeChange(date, 'endTime')}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={30}
            timeCaption="時間"
            dateFormat="yyyy-MM-dd HH:mm"
            disabled={!isEditable}
          />
        </div>
        <div className="form-group">
          <label>場地</label>
          <select 
            name="venue" 
            value={formData.venue} 
            onChange={handleChange} 
            required
            disabled={!isEditable}
          >
            <option value="">選擇場地</option>
            {venues.map(venue => (
              <option key={venue} value={venue}>場地 {venue}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="form-row participants">
        {formData.participants.map((participant, index) => (
          <input
            key={index}
            type="text"
            value={participant}
            onChange={(e) => handleParticipantChange(index, e.target.value)}
            placeholder={`參與者 ${index + 1}`}
            disabled={!isEditable}
          />
        ))}
      </div>

      <div className="form-row">
        <textarea
          name="notes"
          value={formData.notes}
          onChange={handleChange}
          placeholder="備註"
          disabled={!isEditable}
        />
      </div>

      <div className="form-group">
        <label>週期</label>
          <select 
            name="recurrence" 
            value={recurrence} 
            onChange={(e) => setRecurrence(e.target.value)}
            disabled={!isEditable}
          >
            <option value="none">無週期</option>
            <option value="weekly">每週</option>
            <option value="biweekly">隔週</option>
            <option value="monthly">每四週</option>
          </select>
      </div>

      {recurrence !== 'none' && (
        <div className="form-group">
          <label>週期結束時間</label>
          <select
            name="recurrenceEnd"
            value={recurrenceEnd}
            onChange={(e) => setRecurrenceEnd(e.target.value)}
            disabled={!isEditable}
          >
            <option value="3months">3個月</option>
            <option value="6months">半年</option>
          </select>
        </div>
      )}

      {initialData && (
        <div className="form-group">
          <label>更新範圍</label>
          <select
            name="updateType"
            value={updateType}
            onChange={(e) => setUpdateType(e.target.value)}
            disabled={!isEditable}
          >
            <option value="single">僅更新此預約</option>
            <option value="all">更新所有週期預約</option>
          </select>
        </div>
      )}

      {initialData && (
        <div className="form-group">
          <label>刪除範圍</label>
          <select
            name="deleteType"
            value={deleteType}
            onChange={(e) => setDeleteType(e.target.value)}
            disabled={!isEditable}
          >
            <option value="single">僅刪除此預約</option>
            <option value="all">刪除所有週期預約</option>
          </select>
        </div>
      )}

      <div className="form-row buttons">
        {isEditable && (
          <button type="submit" className="submit-btn">
            {initialData ? '更新預約' : '創建預約'}
          </button>
        )}
        <button type="button" onClick={onCancel} className="cancel-btn">取消</button>
        {isEditable && onDelete && (
          <button type="button" onClick={handleDelete} className="delete-btn">
            刪除預約
          </button>
        )}
      </div>
    </form>
  );
};

export default BookingForm;