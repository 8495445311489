import React, { useEffect } from 'react';

const ExternalRedirect = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null; // 返回 null，因为这个组件不需要渲染任何 JSX
}

export default ExternalRedirect;
