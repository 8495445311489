// Projects.js
import React, { useState } from 'react';
import './Projects.css';
import bookingSystemGif from './images/booking_system_intro_20240721.gif';

const Projects = () => {
  const [showGif, setShowGif] = useState(false);
  const [currentGif, setCurrentGif] = useState('');
  const [showUnderConstruction, setShowUnderConstruction] = useState(false);

  const handleButtonClick = (gifName) => {
    if (['project2', 'project3'].includes(gifName)) {
      setShowUnderConstruction(true);
      setShowGif(false);
    } else {
      setShowGif(true);
      setCurrentGif(gifName);
      setShowUnderConstruction(false);
    }
  };

  const handleCloseClick = () => {
    setShowGif(false);
    setCurrentGif('');
    setShowUnderConstruction(false);
  };

  return (
    <div className="projects">
      {!showGif && !showUnderConstruction && (
        <div className="button-group">
          <button className="button-projects" onClick={() => handleButtonClick('booking')}>
            Member Facility Reservation System
          </button>
          <button className="button-projects" onClick={() => handleButtonClick('project2')}>
            Project 2
          </button>
          <button className="button-projects" onClick={() => handleButtonClick('project3')}>
            Project 3
          </button>
        </div>
      )}
      {showGif && (
        <>
          <div className="gif-container">
            {currentGif === 'booking' && (
              <img src={bookingSystemGif} alt="Booking System Introduction" className="gif-image" />
            )}
            <div className="gif-overlay">GIF Playing</div>
          </div>
          <button className="close-button" onClick={handleCloseClick}>
            Close
          </button>
        </>
      )}
      {showUnderConstruction && (
        <div className="under-construction">
          <h2>Under Construction</h2>
          <button className="close-button" onClick={handleCloseClick}>
            Close
          </button>
        </div>
      )}
    </div>
  );
}

export default Projects;